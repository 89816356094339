"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropDownModule = void 0;
var magicline_api_1 = require("../../api/magicline-api");
var object_service_1 = require("../../object-service");
var ci_component_injector_service_1 = require("../../services/ci-component-injector.service");
var settings_service_1 = require("../../services/settings.service");
var error_handling_1 = require("../../error-handling");
var dropdown_helper_1 = require("./helpers/dropdown.helper");
var DropDownModule = /** @class */ (function () {
    function DropDownModule() {
        this.ciComponentInjectorService = object_service_1.ObjectService.getInstance(ci_component_injector_service_1.CiComponentInjectorService);
        this.magiclineApi = object_service_1.ObjectService.getInstance(magicline_api_1.MagiclineApi);
        this.config = object_service_1.ObjectService.getInstance(settings_service_1.SettingsService);
        this.errorHandling = object_service_1.ObjectService.getInstance(error_handling_1.ErrorHandling);
        this.settings = {};
    }
    DropDownModule.prototype.sendForm = function () {
        throw new Error('Method not implemented.');
    };
    DropDownModule.prototype.loadModule = function ($container, settings) {
        return __awaiter(this, void 0, void 0, function () {
            var dropdownType, containerType, dropdownOptions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.settings = settings;
                        this.container = $container;
                        dropdownType = settings.type;
                        if (!dropdownType) {
                            console.error("Unset dropdown type, make sure to set data-type='' to the correct value");
                            return [2 /*return*/];
                        }
                        containerType = $container.prop("tagName");
                        if (containerType !== "SELECT") {
                            console.error("dropdown module has to be inside a SELECT, make sure you use a select-field and not a", containerType);
                            return [2 /*return*/];
                        }
                        console.log("dropdown settings", settings);
                        return [4 /*yield*/, (0, dropdown_helper_1.generateDropdown)(dropdownType)];
                    case 1:
                        dropdownOptions = _a.sent();
                        this.container.append(dropdownOptions);
                        return [2 /*return*/];
                }
            });
        });
    };
    return DropDownModule;
}());
exports.DropDownModule = DropDownModule;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dropdownPreset = void 0;
var dropdownPreset;
(function (dropdownPreset) {
    dropdownPreset["dateOfBirthDay"] = "dateOfBirthDay";
    dropdownPreset["dateOfBirthMonth"] = "dateOfBirthMonth";
    dropdownPreset["dateOfBirthYear"] = "dateOfBirthYear";
    dropdownPreset["studioLocation"] = "studioLocation";
})(dropdownPreset || (exports.dropdownPreset = dropdownPreset = {}));

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrialBookingModule = void 0;
var magicline_api_1 = require("../../api/magicline-api");
var input_output_helper_const_1 = require("../../const/input-output-helper.const");
var input_mapping_const_1 = require("../../const/input.mapping.const");
var object_service_1 = require("../../object-service");
var ci_component_injector_service_1 = require("../../services/ci-component-injector.service");
var trial_booking_helper_1 = require("./helpers/trial-booking.helper");
var settings_service_1 = require("../../services/settings.service");
var error_handling_1 = require("../../error-handling");
var TrialBookingModule = /** @class */ (function () {
    function TrialBookingModule() {
        this.ciComponentInjectorService = object_service_1.ObjectService.getInstance(ci_component_injector_service_1.CiComponentInjectorService);
        this.magiclineApi = object_service_1.ObjectService.getInstance(magicline_api_1.MagiclineApi);
        this.config = object_service_1.ObjectService.getInstance(settings_service_1.SettingsService);
        this.errorHandling = object_service_1.ObjectService.getInstance(error_handling_1.ErrorHandling);
        // public mapping: { 'data-no-future-dates': boolean };
        // public settings: { noFutureDates: boolean };
        this.trialBookingMapping = input_mapping_const_1._TrialBookingMapping;
        this.trialBookins = {
            description: '',
            name: '',
            slots: []
        };
        this.settings = {};
        this.loadingIndicator = $("\n    <div style=\"display:flex;justify-content:center; width: 100%;\">\n      <img style=\"height: 64px; width: 64px;\" src=\"https://files.scaleyourgym.com/files/webflow/new-magicline/dev/assets/loading.svg\">\n    </div>");
        // Give event listener access to ".this" context
        this.handleDatepickerChangeEvent = this.handleDatepickerChangeEvent.bind(this);
        this.slotSelected = this.slotSelected.bind(this);
        this.submitTrialBooking = this.submitTrialBooking.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
        this.studioLocationDropdownSelected = this.studioLocationDropdownSelected.bind(this);
    }
    TrialBookingModule.prototype.sendForm = function () {
        throw new Error('Method not implemented.');
    };
    //TODO: TYPESAFETY FOR SETTINGS
    TrialBookingModule.prototype.loadModule = function ($container, settings) {
        return __awaiter(this, void 0, void 0, function () {
            var datePickerQuery, datepickerColorObject, datePickerColors, submitButtonQuery;
            return __generator(this, function (_a) {
                this.settings = settings;
                this.container = $container;
                // base style classes
                $('head').append((0, trial_booking_helper_1.getTrialBookingBaseStyles)());
                console.log('settings', this.settings, this.config.get.trialBooking);
                // load ci-components
                this.ciComponentInjectorService.loadScript();
                // initial selected date
                this.selectedStartDate = new Date();
                this.selectedEndDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
                this.selectedDate = (0, trial_booking_helper_1.getCurrentDate)();
                // location selection element
                // TODO: accept local storage so user can set it outside of this module
                this.locationSelection = $container.find('*[data-module="dropdown"][data-type="studioLocation"]');
                if (this.locationSelection.length != 0) {
                    this.locationSelection.on('change', this.studioLocationDropdownSelected);
                }
                else {
                    console.error("Trialbooking did not find a dropdown of type studioLocation");
                }
                datePickerQuery = $container.find('*[data-ref="datepicker"]');
                if (datePickerQuery.length > 0) {
                    datepickerColorObject = this.config.get.trialBooking.colors;
                    datePickerColors = datepickerColorObject ? 'colors="' + JSON.stringify(datepickerColorObject).replace(/\"/g, "'") + '"' : "";
                    this.datePicker = $("<ci-datepicker close-on-selection=\"false\" ".concat(datePickerColors, " animations=\"false\" disable-past-days=\"true\" disable-future-days=\"true\"></ci-datepicker>"));
                    this.changeDatepickerStatus("disabled");
                    datePickerQuery.append(this.datePicker);
                    datePickerQuery.on('change', 'ci-datepicker', this.handleDatepickerChangeEvent);
                }
                else {
                    console.error("Trial booking module missing a data-ref='datepicker' container");
                }
                // slot picker
                this.slotPicker = $container.find('*[data-ref="slotpicker"]');
                if (this.slotPicker.length > 0) {
                    this.slotPicker.empty();
                    // TODO Read data styles for elements
                }
                else {
                    console.error("Trial booking module missing a data-ref='slotpicker' container");
                }
                // form
                this.formWrapper = $container.find('*[data-ref="form"]');
                if (this.formWrapper.length > 0) {
                    this.formWrapper.css({
                        'display': 'none',
                    });
                    // TODO Read data styles for elements
                }
                else {
                    console.error("Trial booking module missing a data-ref='form' container");
                }
                submitButtonQuery = $container.find('*[data-action="trailbooking.submit"]');
                if (submitButtonQuery.length > 0) {
                    console.log("Found a submit button");
                    submitButtonQuery.off();
                    submitButtonQuery.on('click', this.submitTrialBooking);
                }
                else {
                    console.error("no button with data-action='trailbooking.submit' found!");
                }
                // webflow submit button
                this.webflowSubmitButton = $container.find('*[data-action="webflow-submit"]');
                if (this.webflowSubmitButton.length === 0) {
                    console.error("no button with data-action='webflow-submit' found!");
                }
                // back button
                this.backButton = $container.find('*[data-action="back"]');
                if (this.backButton.length > 0) {
                    console.log("Found a back button");
                    this.backButton.on('click', this.handleBackButton);
                }
                // selected slot display
                // default format is "[from] - [to], [day] [month], [year]"
                // only displayed when slot is selected (when the customer enters the form)
                this.selectedSlotWrapper = $container.find('*[data-get="selectedSlot"]');
                if (this.selectedSlotWrapper.length > 0) {
                    this.selectedSlotWrapper.css({
                        'display': 'none',
                    });
                    this.selectedSlotElement = $('<div></div>');
                    this.selectedSlotWrapper.append(this.selectedSlotElement);
                }
                return [2 /*return*/];
            });
        });
    };
    TrialBookingModule.prototype.handleBackButton = function (event) {
        event.preventDefault();
        console.log("back button clicked");
        this.formWrapper.css({
            'display': 'none',
        });
        this.selectedSlotWrapper.css({
            'display': 'none',
        });
    };
    TrialBookingModule.prototype.getTrailBookingSlots = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.selectedStartDate && this.selectedEndDate && this.studioId)) return [3 /*break*/, 4];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        this.slotPicker.append(this.loadingIndicator);
                        _a = this;
                        return [4 /*yield*/, this.magiclineApi.getTrialBookings(this.studioId, this.selectedStartDate, this.selectedEndDate)];
                    case 2:
                        _a.trialBookins = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        console.error("Error trying to get magicline trail-booking slots:", e_1);
                        this.trialBookins = {
                            description: "",
                            name: "",
                            slots: [],
                        };
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    TrialBookingModule.prototype.updateTrialBookings = function () {
        return __awaiter(this, void 0, void 0, function () {
            var uniqueDays, datepicker;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getTrailBookingSlots()];
                    case 1:
                        _a.sent();
                        uniqueDays = this.getUniqueDays(this.trialBookins);
                        this.datePicker.prop('highlight-days', uniqueDays);
                        datepicker = $('ci-datepicker')[0];
                        datepicker.highlightDays = uniqueDays;
                        return [2 /*return*/];
                }
            });
        });
    };
    // takes the trial booking slots and returns unique dates of each day
    TrialBookingModule.prototype.getUniqueDays = function (trialBookins) {
        var days = trialBookins.slots.map(function (slot) { return slot.startDateTime.split('T')[0]; });
        var daysUnique = days.filter(function (value, index, array) { return array.indexOf(value) === index; });
        return daysUnique.map(function (x) { return new Date(x); });
    };
    TrialBookingModule.prototype.updateSlotSelection = function () {
        var _this = this;
        var applicableSlots = this.trialBookins.slots.filter(function (slot) { return slot.startDateTime.split('T')[0] === _this.selectedDate; });
        console.log('applicable slots:', applicableSlots, "for selected date", this.selectedDate);
        this.slotPicker.empty();
        var slotWrapper = $('<div style="display:flex;flex-wrap:wrap;margin-top: 1.5em;"></div>');
        applicableSlots.forEach(function (slot) {
            var _a, _b;
            var slotDetails = (0, trial_booking_helper_1.slotToDetails)(slot);
            // a slot has two elements, a div and a span for the text
            // it uses base classes we design here and adds classes for the designer to write
            // the non base classes come after the base classes to overwrite the base style
            var startTime = slotDetails.formattedStartTime;
            var endTime = slotDetails.formattedEndTime;
            var slotText = startTime + ' - ' + endTime;
            var slotDiv = $('<div class="fittec-trial-booking-slot-wrapper"></div>');
            var slotSpan = $('<span class="fittec-trial-booking-slot-text"></span>').text(slotText);
            if (slot.startDateTime === ((_a = _this.selectedSlot) === null || _a === void 0 ? void 0 : _a.startDateTime) &&
                slot.endDateTime === ((_b = _this.selectedSlot) === null || _b === void 0 ? void 0 : _b.endDateTime)) {
                slotDiv.addClass('fittec-trial-booking-slot-wrapper-selected');
                slotSpan.addClass('fittec-trial-booking-slot-text-selected');
            }
            slotDiv.append(slotSpan);
            slotDiv.on('click', function () { return _this.slotSelected(slot); });
            slotWrapper.append(slotDiv);
        });
        this.slotPicker.append(slotWrapper);
    };
    TrialBookingModule.prototype.slotSelected = function (slot) {
        console.log('selected slot', slot);
        this.selectedSlot = slot;
        this.formWrapper.css({
            'display': 'block',
        });
        if (this.selectedSlotWrapper) {
            this.selectedSlotWrapper.css({
                'display': 'block',
            });
            this.setSelectedSlotWrapperText();
        }
        // load pre-existing data from local storage
        this.readLocalStorage();
        // render slot selection
        this.updateSlotSelection();
        // replace placeholders that use selected slot info
        (0, trial_booking_helper_1.replaceTrialBookingPlaceholdersTimeslots)(this.container, this.selectedSlot);
    };
    TrialBookingModule.prototype.readLocalStorage = function () {
        var contrainerRef = this.container;
        $.each(this.trialBookingMapping, function (key, value) {
            console.log("key: ", key, value);
            // Construct the selector for the elements
            var selector = "[data-set=\"".concat(value.uid, "\"]");
            // Get the value from localStorage
            var storedValue = localStorage.getItem(value.uid) || '';
            console.log("got local storage", storedValue, value.uid);
            // Find the elements and set their value
            if (selector.length > 0) {
                $(selector).val(storedValue);
            }
            // date of birth is handled differently
            if (key === "dateOfBirth" && storedValue.length > 0 && storedValue.split("-").length === 3) {
                var split = storedValue.split("-");
                console.log("setting values", split[0], split[1], split[2]);
                contrainerRef.find('*[data-get="customer.dayOfBirth"]').val(+split[2]);
                contrainerRef.find('*[data-get="customer.monthOfBirth"]').val(+split[1]);
                contrainerRef.find('*[data-get="customer.yearOfBirth"]').val(+split[0]);
            }
        });
    };
    // is a studio selection dropdown was found inside the trial-booking module use it to set studioId
    TrialBookingModule.prototype.studioLocationDropdownSelected = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var selectedValue, datepicker;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        selectedValue = $(event.target).val();
                        console.log('Selected studio location:', selectedValue);
                        // hide form again
                        this.formWrapper.css({
                            'display': 'none',
                        });
                        this.selectedSlotWrapper.css({
                            'display': 'none',
                        });
                        this.selectedSlot = undefined;
                        this.slotPicker.empty();
                        if (!!isNaN(selectedValue)) return [3 /*break*/, 2];
                        this.studioId = selectedValue;
                        this.changeDatepickerStatus("enabled");
                        return [4 /*yield*/, this.updateTrialBookings()];
                    case 1:
                        _a.sent();
                        this.updateSlotSelection();
                        return [3 /*break*/, 3];
                    case 2:
                        // this happens if the first STUDIO option is selected with no value
                        // reset to nothing
                        this.trialBookins = {
                            description: "",
                            name: "",
                            slots: [],
                        };
                        datepicker = $('ci-datepicker')[0];
                        datepicker.highlightDays = [];
                        this.changeDatepickerStatus("disabled");
                        this.formWrapper.css({
                            'display': 'none',
                        });
                        this.slotPicker.css({
                            'display': 'none',
                        });
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    // get datepicker month + year, make sure to use UTC to prevent conversion errors
    // start is first day of that month, end is last day of the month
    // careful with right dates, if span is bigger than a month, API throws error
    TrialBookingModule.prototype.handleDatepickerChangeEvent = function (event) {
        var _a, _b, _c;
        console.log('datepicker event', event);
        // new month selected
        if (((_b = (_a = event.detail) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.type) === 'days-view') {
            try {
                var newDate = event.detail.data.value.year + '-' + event.detail.data.value.month + '-' + event.detail.data.value.day;
                if (this.datePickerDate != newDate) {
                    console.log('refreshing to new date, old:', this.datePickerDate, 'new', newDate);
                    this.datePickerDate = newDate;
                    var startDate = new Date(Date.UTC(event.detail.data.value.year, event.detail.data.value.month, 1));
                    var endDate = new Date(Date.UTC(event.detail.data.value.year, event.detail.data.value.month + 1, 0));
                    // start date uses first day of the selected month, if we currently are in this month, skip the days already in the past
                    if (startDate < new Date()) {
                        startDate = new Date();
                    }
                    this.selectedStartDate = startDate;
                    this.selectedEndDate = endDate;
                    // if the end date is in the past, dont load anything
                    if (endDate > new Date()) {
                        this.updateTrialBookings();
                    }
                }
            }
            catch (e) {
                console.error('failed getting date from datepicker:', e);
            }
        }
        else if (((_c = event.detail) === null || _c === void 0 ? void 0 : _c.statusText) === 'value-changed') {
            // day selected
            console.log('value changed!');
            this.selectedDate = event.detail.data;
            this.slotPicker.css({
                'display': 'block',
            });
            this.formWrapper.css({
                'display': 'none',
            });
            this.selectedSlotWrapper.css({
                'display': 'none',
            });
            this.updateSlotSelection();
        }
    };
    TrialBookingModule.prototype.submitTrialBooking = function (event) {
        event.preventDefault();
        console.log('submit!');
        var validationRes = (0, input_output_helper_const_1._ValidateFields)(this.container, {
            showErrors: true,
            showSuccess: false,
            animateToFirstError: true,
            mappings: this.trialBookingMapping,
        }, true);
        // TODO:  event.fire()
        // create event with custom attribute to let through
        if (!this.selectedSlot) {
            console.error("No slot selected for trial session!");
            return;
        }
        if (validationRes.valid) {
            var firstnameQ = this.container.find('*[data-get="customer.firstname"]');
            var lastnameQ = this.container.find('*[data-get="customer.lastname"]');
            if (firstnameQ.length > 0 && lastnameQ.length > 0) {
                // whole booking data
                var bookingData = (0, trial_booking_helper_1.createTrialBookingData)(this.container, this.selectedSlot, this.studioId);
                console.log("booking data", bookingData);
                try {
                    this.magiclineApi.createTrialBooking(bookingData);
                }
                catch (e) {
                    console.error("Error trying to create a trail booking:", e);
                }
                this.webflowSubmitButton.trigger("click");
            }
            else {
                console.error("firstname or lastname field not found", firstnameQ, lastnameQ);
            }
        }
        else {
            console.error("trail booking form validation failed:", validationRes);
            this.errorHandling.showErrorMessage(validationRes.invalidFields[0].regexError);
        }
    };
    TrialBookingModule.prototype.setSelectedSlotWrapperText = function () {
        this.selectedSlotElement.remove();
        this.selectedSlotElement = (0, trial_booking_helper_1.createSelectedTimeslotContainer)(this.selectedSlot);
        this.selectedSlotWrapper.append(this.selectedSlotElement);
    };
    TrialBookingModule.prototype.changeDatepickerStatus = function (newStatus) {
        if (newStatus == "enabled") {
            this.datePicker.attr('disable-future-days', 'false');
            this.datePicker.attr('disable-past-days', 'false');
        }
        else {
            this.datePicker.attr('disable-future-days', 'true');
            this.datePicker.attr('disable-past-days', 'true');
        }
    };
    return TrialBookingModule;
}());
exports.TrialBookingModule = TrialBookingModule;

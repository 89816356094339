"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateDropdown = generateDropdown;
exports.generateDayOfBirthDropdown = generateDayOfBirthDropdown;
exports.generateMonthOfBirthDropdown = generateMonthOfBirthDropdown;
exports.generateYearOfBirthDropdown = generateYearOfBirthDropdown;
exports.generateStudioLocationDropdown = generateStudioLocationDropdown;
var api_wrapper_1 = require("../../../api/api-wrapper");
var object_service_1 = require("../../../object-service");
var dropdown_model_1 = require("../models/dropdown.model");
var selectClasses = 'text-field w-select';
function generateDropdown(preset) {
    return __awaiter(this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = preset;
                    switch (_a) {
                        case dropdown_model_1.dropdownPreset.dateOfBirthDay: return [3 /*break*/, 1];
                        case dropdown_model_1.dropdownPreset.dateOfBirthMonth: return [3 /*break*/, 2];
                        case dropdown_model_1.dropdownPreset.dateOfBirthYear: return [3 /*break*/, 3];
                        case dropdown_model_1.dropdownPreset.studioLocation: return [3 /*break*/, 4];
                    }
                    return [3 /*break*/, 6];
                case 1: return [2 /*return*/, generateDayOfBirthDropdown()];
                case 2: return [2 /*return*/, generateMonthOfBirthDropdown()];
                case 3: return [2 /*return*/, generateYearOfBirthDropdown()];
                case 4: return [4 /*yield*/, generateStudioLocationDropdown()];
                case 5: return [2 /*return*/, _b.sent()];
                case 6:
                    console.error('Unknown dropdown type:', preset);
                    return [2 /*return*/, []];
            }
        });
    });
}
function generateDayOfBirthDropdown() {
    var options = [];
    for (var day = 1; day <= 31; day++) {
        var option = $('<option></option>').val(day).text(day);
        options.push(option);
    }
    return options;
}
function generateMonthOfBirthDropdown() {
    var monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
    ];
    var options = [];
    for (var month = 0; month <= 11; month++) {
        var option = $('<option></option>')
            .val(month + 1)
            .text(monthNames[month]);
        options.push(option);
    }
    return options;
}
function generateYearOfBirthDropdown() {
    var currentYear = new Date().getFullYear();
    var options = [];
    for (var year = currentYear; year > currentYear - 100; year--) {
        var option = $('<option></option>').val(year).text(year);
        options.push(option);
    }
    return options;
}
function generateStudioLocationDropdown() {
    return __awaiter(this, void 0, void 0, function () {
        var apiWrapper, studios, options;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    apiWrapper = object_service_1.ObjectService.getInstance(api_wrapper_1.ApiWrapper);
                    return [4 /*yield*/, apiWrapper.getStudios()];
                case 1:
                    studios = _a.sent();
                    options = [];
                    studios.forEach(function (studio) {
                        options.push($("<option value=\"".concat(studio.id, "\">").concat(studio.studioName, "</option>")));
                    });
                    console.log('got studios', studios);
                    return [2 /*return*/, options];
            }
        });
    });
}

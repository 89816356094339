"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.slotToDetails = slotToDetails;
exports.createSelectedTimeslotContainer = createSelectedTimeslotContainer;
exports.createTrialBookingData = createTrialBookingData;
exports.replaceTrialBookingPlaceholdersTimeslots = replaceTrialBookingPlaceholdersTimeslots;
exports.getTrialBookingBaseStyles = getTrialBookingBaseStyles;
exports.getCurrentDate = getCurrentDate;
function slotToDetails(selectedSlot) {
    var startDateTime = new Date(selectedSlot.startDateTime);
    var endDateTime = new Date(selectedSlot.endDateTime);
    // Calculate the duration in minutes
    var totalMinutes = Math.round((endDateTime.getTime() - startDateTime.getTime()) / (1000 * 60));
    // Calculate hours and remaining minutes
    var hours = Math.floor(totalMinutes / 60);
    var minutes = totalMinutes % 60;
    // Format the duration string
    var duration;
    if (hours > 0) {
        if (minutes > 0) {
            duration = "".concat(hours, " Std. ").concat(minutes, " Min");
        }
        else {
            if (hours > 1) {
                duration = "".concat(hours, " Stunden");
            }
            else {
                duration = "".concat(hours, " Stunde");
            }
        }
    }
    else {
        duration = "".concat(minutes, " Min");
    }
    // Format the date and time
    var options = {
        weekday: 'long', // "long" | "short" | "narrow"
        year: 'numeric', // "numeric" | "2-digit"
        month: 'long', // "long" | "short" | "narrow" | "numeric" | "2-digit"
        day: 'numeric', // "numeric" | "2-digit"
    };
    var formattedStartTime = startDateTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
    });
    var formattedEndTime = endDateTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
    });
    var formattedDate = startDateTime.toLocaleDateString(undefined, options);
    // Get the timezone
    var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return {
        duration: duration,
        formattedStartTime: formattedStartTime,
        formattedEndTime: formattedEndTime,
        formattedDate: formattedDate,
        timezone: timezone,
    };
}
function createSelectedTimeslotContainer(selectedSlot) {
    var slotDetails = slotToDetails(selectedSlot);
    return $("\n       <div style=\"margin: 5px;\">\n         <div style=\"display:flex; align-items: center; margin: 5px;\">\n           <ci-icon icon=\"clock\" size=\"30px\" style=\"margin-right: 5px;\"></ci-icon>\n           <span>".concat(slotDetails.duration, "</span>\n         </div>\n         <div style=\"display:flex; align-items: center; margin: 5px;\">\n           <ci-icon icon=\"calendar\" size=\"30px\" style=\"margin-right: 5px;\"></ci-icon>\n           <span>").concat(slotDetails.formattedStartTime, " - ").concat(slotDetails.formattedEndTime, ", ").concat(slotDetails.formattedDate, "</span>\n         </div>\n         <div style=\"display:flex; align-items: center; margin: 5px;\">\n           <ci-icon icon=\"earth\" size=\"30px\" style=\"margin-right: 5px;\"></ci-icon>\n           <span>").concat(slotDetails.timezone, "</span>\n         </div>\n       <div>"));
}
function createTrialBookingData($container, selectedSlot, studioId) {
    // whole booking data
    var customerDateOfBirth = $container.find('*[data-get="customer.yearOfBirth"]').val() +
        '-' +
        $container.find('*[data-get="customer.monthOfBirth"]').val().padStart(2, '0') +
        '-' +
        $container.find('*[data-get="customer.dayOfBirth"]').val().padStart(2, '0');
    return {
        studioId: studioId,
        startDateTime: selectedSlot.startDateTime,
        leadCustomer: {
            firstname: $container
                .find('*[data-get="customer.firstname"]')
                .val(),
            lastname: $container
                .find('*[data-get="customer.lastname"]')
                .val(),
            gender: $container.find('*[data-get="customer.gender"]').val(),
            dateOfBirth: customerDateOfBirth,
            email: $container.find('*[data-get="customer.email"]').val(),
            phone: $container.find('*[data-get="customer.telephone_mobile"]').val(),
        },
    };
}
// convert {{trialSlotDuration}} to "data-placeholder-trial-slot-duration"
function formatPlaceholder(str) {
    return str.replace(/\{/g, '').replace(/\}/g, '').replace(/[A-Z]/g, function (letter) { return "-".concat(letter.toLowerCase()); });
}
function createPlaceholderSpan(key, value) {
    var dataTag = "data-placeholder-" + formatPlaceholder(key) + '="true"';
    var elem = $('<span style="display:inline;" ' + dataTag + '></span>');
    elem.text(value);
    return elem;
}
function replaceTrialBookingPlaceholdersTimeslots($container, selectedSlot) {
    var elements = $container.find('*[data-get="trialbookingPlaceholder"]');
    var slotDetails = slotToDetails(selectedSlot);
    var mappings = [
        { key: '{{trialSlotDuration}}', value: slotDetails.duration },
        { key: '{{trialSlotStartTime}}', value: slotDetails.formattedStartTime },
        { key: '{{trialSlotEndTime}}', value: slotDetails.formattedEndTime },
        { key: '{{trialSlotDate}}', value: slotDetails.formattedDate },
        { key: '{{trialSlotTimezone}}', value: slotDetails.timezone },
    ];
    // search for data-placeholder spans (generated by replacing {{placeholder}})
    mappings.forEach(function (mapping) {
        var dataTag = formatPlaceholder(mapping.key);
        var elems = $container.find('*[data-placeholder-' + dataTag + '="true"]');
        elems.text(mapping.value);
        console.log("found", elems, "with tag", '*[data-placeholder-' + dataTag + '="true"]', "and set text to", mapping.value);
    });
    // search for data-get placeholders
    elements.each(function () {
        var content = $(this).html(); // Get the HTML content of the current element
        var originalContent = content;
        console.log('current element has this html', content);
        mappings.forEach(function (mapping) {
            var regex = new RegExp(mapping.key, 'g'); // Create a regex to replace all occurrences of the key
            if (regex.test(content)) { // Check if the regex finds a match
                var generatedElem = createPlaceholderSpan(mapping.key, mapping.value);
                content = content.replace(regex, generatedElem.prop("outerHTML")); // Replace the key with the value
            }
        });
        console.log('after replacing placeholders', content);
        // Update the HTML only if it has changed
        if (content !== originalContent) {
            $(this).html(content); // Set the updated content back to the element
        }
    });
}
function getTrialBookingBaseStyles() {
    var customStyles = "\n    .fittec-trial-booking-slot-wrapper {\n      border:1px solid rgb(255, 137, 80); \n      margin: 5px; \n      border-radius: 5px; \n      padding: 5px;\n    }\n    .fittec-trial-booking-slot-text {\n        color: rgb(255, 137, 80);\n        font-weight: 700;\n    }\n    .fittec-trial-booking-slot-wrapper-selected {\n      \n    }\n    .fittec-trial-booking-slot-text-selected {\n        border-width: 3px; \n    }\n  ";
    // Create a <style> element
    var styleElement = $('<style></style>');
    // Add the CSS to the <style> element
    styleElement.text(customStyles);
    return styleElement;
}
function getCurrentDate() {
    var currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
}
